import Experience from "./Experience";
import EventEmitter from "./Utils/EventEmitter";
import gsap from "gsap";

export default class Html extends EventEmitter {
  constructor() {
    super();

    this.children = null;
    this.experience = new Experience();

    this.resources = this.experience.resources;
    this.body = document.querySelector("body");

    //? loadingScreen
    this.loadingScreen = document.createElement("div");
    this.loadingScreen.classList.add("loading-container");

    this.loadingScreen.innerHTML = `
    <div class="loading-img">
        <img src="" alt="" />
        <div class="loading-spinner"></div>
        <div class="loading-spinner-inner"></div>
      </div>
    `;

    this.clickContainer = document.createElement("div");
    this.clickContainer.classList.add("clickContainer");

    this.clickContainer.innerHTML = `
     

       <div class="point point-1">
        <div class="inside-point small-boxes"></div>
        <div class="inside-pulse inside-pulse-1"></div>
      </div>

       <div class="point point-2">
        <div class="inside-point left-rack"></div>
        <div class="inside-pulse inside-pulse-2"></div>
      </div>

       <div class="point point-3">
        <div class="inside-point right-rack"></div>
        <div class="inside-pulse inside-pulse-3"></div>
      </div>

       <div class="point point-4">
        <div class="inside-point behind-table"></div>
        <div class="inside-pulse inside-pulse-4"></div>
      </div>

       <div class="point point-5">
        <div class="inside-point fence"></div>
        <div class="inside-pulse inside-pulse-5"></div>
      </div>

    

      <div class="home-btn">Home</div>
      <div class="review-product">
         <a href="" target="_blank">Review Product</a>
      </div>
    `;

    this.body.append(this.clickContainer);
    this.body.append(this.loadingScreen);

    //! resources ready
    this.resources.on("resourcesReady", () => {
      gsap.to(this.loadingScreen, {
        duration: 1,
        opacity: 0,
        delay: 1,
        onComplete: () => {
          this.loadingScreen.style.display = "none";
          gsap.to(this.clickContainer, {
            duration: 0.5,
            opacity: 1,
          });
        },
      });
    });

    //?
  }
}
