export default [
  {
    name: "object",
    type: "gltfLoader",
    path: "/models/tent-draco.glb",
  },

  {
    name: "environmentTexture",
    type: "rgbeLoader",
    path: "/textures/day-1k.hdr",
  },

  {
    name: "aoMap",
    type: "textureLoader",
    path: "/textures/ao_map.png",
  },
];
