import * as THREE from "three";
import Experience from "./Experience";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

export default class Camera {
  constructor() {
    this.experience = new Experience();

    this.sizes = this.experience.sizes;
    this.canvas = this.experience.canvas;
    this.scene = this.experience.scene;

    this.cameraObject = {
      fov: 45,
      aspect: this.sizes.aspect,
      near: 0.1,
      far: 8000,
    };

    this.setUpPerspectiveCamera();

    this.setUpControls();
  }

  setUpPerspectiveCamera() {
    this.perspectiveCamera = new THREE.PerspectiveCamera(
      this.cameraObject.fov,
      this.cameraObject.aspect,
      this.cameraObject.near,
      this.cameraObject.far
    );

    this.perspectiveCamera.rotation.set(-1.59, 1.49, 1.59);

    if (this.sizes.width > 600) {
      this.perspectiveCamera.position.set(43.149, 8.99, -1.35);
    } else {
      this.perspectiveCamera.position.set(63.149, 8.99, 2.05);
    }
  }

  setUpControls() {
    this.controls = new OrbitControls(this.perspectiveCamera, this.canvas);
    this.controls.enableDamping = true;
    // this.controls.autoRotate = true;

    this.controls.enablePan = false;
    // //? horizontal limitation
    this.controls.minAzimuthAngle = Math.PI / 3;
    this.controls.maxAzimuthAngle = Math.PI / 1.5;

    // //? up and down limitation
    this.controls.minPolarAngle = Math.PI / 2.5;
    this.controls.maxPolarAngle = Math.PI / 2.05;

    //? how much they can zoom limitation

    if (this.sizes.width > 600) {
      this.controls.minDistance = 40;
      this.controls.maxDistance = 50;
    } else {
      this.controls.minDistance = 40;
      this.controls.maxDistance = 90;
    }
  }

  resize() {
    this.cameraObject.aspect = this.sizes.aspect;
    this.perspectiveCamera.aspect = this.cameraObject.aspect;
    this.perspectiveCamera.updateProjectionMatrix();
  }

  update() {
    this.controls.update();
  }
}
