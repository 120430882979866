import * as lil from "lil-gui";

export default class Debug {
  constructor() {
    this.active = null;

    if (window.location.hash === "#debug") {
      this.active = true;
      this.gui = new lil.GUI();
    }
  }
}
