import * as THREE from "three";
import sources from "./source";
import Sizes from "./Utils/Sizes";
import Time from "./Utils/Time";
import Debug from "./Utils/Debug";
import Resources from "./Utils/Resources";

import Camera from "./Camera";
import Renderer from "./Renderer";
import Html from "./Html";
import World from "./World";

//? UCOMMENT to showcase fps to see website performance

// import Stats from "stats.js";

let instance = null;

export default class Experience {
  constructor(canvas) {
    this.canvas = canvas;
    this.sources = sources;
    this.debug = new Debug();

    if (instance) {
      return instance;
    }

    //? UCOMMENT to showcase fps to see website performance
    // this.stats = new Stats();
    // document.querySelector("body").appendChild(this.stats.dom);

    instance = this;
    window.experience = this;

    this.scene = new THREE.Scene();

    //? UTILS
    this.sizes = new Sizes();
    this.time = new Time();
    this.resources = new Resources(this.sources);

    //? html
    this.html = new Html();

    //? WORLD
    this.camera = new Camera();
    this.renderer = new Renderer();
    this.world = new World();

    this.sizes.on("resize", () => {
      this.resize();
    });

    this.time.on("update", () => {
      this.update();
    });
  }

  resize() {
    this.camera.resize();
    this.renderer.resize();
  }

  update() {
    // this.stats.begin();
    this.camera.update();
    this.renderer.update();
    this.world.update();
    // this.stats.end();
  }
}
