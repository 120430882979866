import * as THREE from "three";
import Experience from "./Experience";
import { GroundedSkybox } from "three/addons/objects/GroundedSkybox.js";
import gsap from "gsap";

export default class World {
  constructor() {
    this.experience = new Experience();
    this.canvas = this.experience.canvas;
    this.sizes = this.experience.sizes;
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.camera = this.experience.camera.perspectiveCamera;
    this.controls = this.experience.camera.controls;
    this.debug = this.experience.debug;

    this.mobile = this.sizes.width < 550 ? true : false;

    this.fence = null;

    this.params = {
      height: 10,
      radius: 100,
      enabled: true,
    };

    //!raycasting - parallax
    this.rayCaster = new THREE.Raycaster();
    this.pointer = new THREE.Vector2();
    this.intersectObjects = [];
    this.clicked = false;

    this.clock = new THREE.Clock();

    window.addEventListener("mousemove", (event) => {
      this.pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
      this.pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;
    });

    //? placing html elements

    //?

    this.clickContainer = document.querySelector(".clickContainer");
    this.homeBtn = document.querySelector(".home-btn");
    this.reviewProductBtn = document.querySelector(".review-product");
    this.reviewProductLink = document.querySelector("a");

    if (!this.mobile) {
      this.points = [
        {
          position: new THREE.Vector3(3, 1.2, 5.25),
          element: document.querySelector(".point-1"),
        },

        {
          position: new THREE.Vector3(-3, 4, 10.25),
          element: document.querySelector(".point-2"),
        },

        {
          position: new THREE.Vector3(-3.4, 4, 0.25),
          element: document.querySelector(".point-3"),
        },

        {
          position: new THREE.Vector3(-12, 4, 6.0),
          element: document.querySelector(".point-4"),
        },

        {
          position: new THREE.Vector3(4.5, 6.5, -14),
          element: document.querySelector(".point-5"),
        },
      ];
    } else {
      this.points = [
        {
          position: new THREE.Vector3(3, -7.5, 5.25),
          element: document.querySelector(".point-1"),
        },

        {
          position: new THREE.Vector3(-3, 4, 10.25),
          element: document.querySelector(".point-2"),
        },

        {
          position: new THREE.Vector3(-3.4, 4, 0.25),
          element: document.querySelector(".point-3"),
        },

        {
          position: new THREE.Vector3(-12, 4, 6.0),
          element: document.querySelector(".point-4"),
        },

        {
          position: new THREE.Vector3(4, 1.25, -5),
          element: document.querySelector(".point-5"),
        },
      ];
    }

    this.resources.on("resourcesReady", () => {
      if (this.sizes.width > 500) {
        this.fog = new THREE.Fog(0xf5f5f5, 5, 400);
        this.scene.fog = this.fog;
      }

      this.envTexture = this.resources.items.environmentTexture;
      this.envTexture.mapping = THREE.EquirectangularReflectionMapping;

      this.shadow = this.resources.items.aoMap;
      this.shadow.flipY = false;

      this.scene.environment = this.envTexture;

      this.skybox = new GroundedSkybox(
        this.envTexture,
        this.params.height,
        this.params.radius
      );

      this.skybox.position.y = this.params.height - 0.01;
      this.scene.add(this.skybox);

      this.object = this.resources.items.object;

      this.object.scene.traverse((child) => {
        if (child.name === "fence") {
          this.fence = child;
        }

        if (child.name === "floor") {
          child.material = new THREE.MeshBasicMaterial({
            map: this.shadow,
            toneMapped: false,
            transparent: true,
            blending: THREE.MultiplyBlending,
          });
        }
      });

      this.object.scene.rotation.y = Math.PI * 0.5;
      this.scene.add(this.object.scene);
      if (!this.mobile) {
        this.controls.target.set(0, 5, 0);
      } else {
        this.controls.target.set(0, 5, 4);
      }

      //? lights

      this.hemisphereLight = new THREE.HemisphereLight(0x567491, 0x576237, 5);
      this.hemisphereLight.position.set(0, 2.5, 0);

      this.scene.add(this.hemisphereLight);

      this.clickContainer.addEventListener("click", (e) => {
        if (e.target.classList.contains("home-btn")) {
          if (!this.mobile) {
            this.cameraAnimation(
              43.149,
              8.99,
              -1.35,
              -1.59,
              1.49,
              1.59,
              0,
              5,
              0,
              40,
              50,
              false
            );
          } else {
            this.cameraAnimation(
              63.149,
              8.99,
              2.05,
              -1.59,
              1.49,
              1.59,
              0,
              5,
              4,
              40,
              90,
              false
            );
          }

          this.reviewProductLink.href = "";
        }

        if (e.target.classList.contains("small-boxes")) {
          if (!this.mobile) {
            this.cameraAnimation(
              6.753,
              5.32,
              5.5,
              -1.5,
              1.1,
              1.5,
              0,
              1,
              4.5,
              5,
              20,
              true
            );
          } else {
            this.cameraAnimation(
              16.92,
              5.01,
              5.38,
              -1.35,
              1.33,
              1.34,
              0,
              1,
              4.5,
              10,
              20,
              true
            );
          }
          this.reviewProductLink.href = "https://www.google.com/";
        }

        if (e.target.classList.contains("left-rack")) {
          if (!this.mobile) {
            this.cameraAnimation(
              7.98,
              4.32,
              10.3,
              -0.9,
              1.52,
              0.9,
              -2,
              4,
              10,
              0,
              10,
              true
            );
          } else {
            this.cameraAnimation(
              10.98,
              4.38,
              10.3,
              -0.9,
              1.52,
              0.9,
              -2,
              4,
              10,
              8,
              15,
              true
            );
          }
          this.reviewProductLink.href = "https://www.google.com/";
        }

        if (e.target.classList.contains("right-rack")) {
          if (!this.mobile) {
            this.cameraAnimation(
              7.13,
              4.32,
              0.13,
              -0.9,
              1.52,
              0.9,
              -2,
              4,
              0,
              5,
              10,
              true
            );
          } else {
            this.cameraAnimation(
              14.13,
              4.32,
              0.13,
              -0.9,
              1.52,
              0.9,
              -2,
              4,
              0,
              10,
              18.5,
              true
            );
          }
          this.reviewProductLink.href = "https://www.google.com/";
        }

        if (e.target.classList.contains("behind-table")) {
          if (!this.mobile) {
            this.cameraAnimation(
              -6.541,
              5.75,
              4.47,
              -1.39,
              1.25,
              1.38,
              -15,
              3,
              4,
              6,
              10,
              true
            );
          } else {
            this.cameraAnimation(
              -6.2,
              5.75,
              4.47,
              -1.39,
              1.25,
              1.38,
              -15,
              3,
              4,
              5,
              12,
              true
            );
          }
          this.reviewProductLink.href = "https://www.google.com/";
        }

        if (e.target.classList.contains("fence")) {
          if (!this.mobile) {
            this.cameraAnimation(
              20.06,
              3.61,
              -15.12,
              -1.77,
              1.53,
              1.77,
              4,
              3,
              -15,
              10,
              20,
              true
            );
          } else {
            this.cameraAnimation(
              58.5,
              3.61,
              -15.12,
              -1.77,
              1.53,
              1.77,
              4,
              3,
              -15,
              40,
              65,
              true
            );
          }
          this.reviewProductLink.href = "https://www.google.com/";
        }
      });
    });
  }

  cameraAnimation(
    pos1,
    pos2,
    pos3,
    rot1,
    rot2,
    rot3,
    targ1,
    targ2,
    targ3,
    minDist,
    maxDist,
    bool
  ) {
    this.controls.minDistance = 0;
    this.controls.maxDistance = Infinity;

    gsap.to(this.camera.position, {
      duration: 2,
      x: pos1,
      y: pos2,
      z: pos3,
      ease: "ease-in",
    });

    gsap.to(this.camera.rotation, {
      duration: 2,
      x: rot1,
      y: rot2,
      z: rot3,
      ease: "ease-in",
    });

    gsap.to(this.controls.target, {
      duration: 2,
      x: targ1,
      y: targ2,
      z: targ3,
      ease: "ease-in",
    });

    if (bool) {
      this.points.forEach((e) => {
        gsap.to(e.element, {
          duration: 0.25,
          opacity: 0,
          ease: "ease-out",
          onComplete: () => {
            e.element.style.display = "none";
            this.homeBtn.style.display = "flex";
            this.reviewProductBtn.style.display = "flex";
            gsap.to(this.reviewProductBtn, {
              duration: 0.5,
              opacity: 1,
              y: 0,
              delay: 2,
              ease: "ease-in",
            });
            gsap.to(this.homeBtn, {
              duration: 0.5,
              opacity: 2,
              delay: 2,
              ease: "ease-in",
            });
          },
        });
      });
    } else if (!bool) {
      gsap.to(this.homeBtn, {
        duration: 0.25,
        opacity: 0,
        ease: "ease-in",
        onComplete: () => {
          this.homeBtn.display = "none";
          this.points.forEach((e) => {
            e.element.style.display = "flex";
            gsap.to(e.element, {
              duration: 0.5,
              opacity: 1,
              delay: 2,
              ease: "ease-in",
            });
          });
        },
      });

      gsap.to(this.reviewProductBtn, {
        duration: 0.25,
        opacity: 0,
        ease: "ease-in",
        onComplete: () => {
          this.reviewProductBtn.display = "none";
        },
      });
    }

    setTimeout(() => {
      this.controls.minDistance = minDist;
      this.controls.maxDistance = maxDist;
    }, 2100);
  }

  update() {
    this.elapsed = this.clock.getElapsedTime();
    if (this.fence) {
      this.fence.rotation.y = (Math.sin(this.elapsed) * 2) / 30;
    }

    if (this.points) {
      for (const point of this.points) {
        this.screenPosition = point.position.clone();
        this.screenPosition.project(this.camera);

        this.translateX = this.screenPosition.x * this.sizes.width * 0.5;
        this.translateY = -this.screenPosition.y * this.sizes.width * 0.3;
        point.element.style.transform = `translateX(${this.translateX}px)  translateY(${this.translateY}px) `;
      }
    }
  }
}
